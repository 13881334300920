<template>
  <div>
   This is a global Component
  </div>
</template>

<script>
export default {
  name: 'Contact',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">

</style>
